import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import Home from './components/Home.js';
import Help from './components/Help.js';
import ListView from './components/ListView.js';
import DashboardStillen from './components/DashboardStillen';
import SignUp from './components/SignUp.js';
import SignIn from './components/SignIn.js';
import Profile from './components/Profile.js';
import InputBoard from './components/InputBoard.js';
import NavBarBottom from './components/NavBarBottom.js';
import BottomDrawer from './components/BottomDrawer_new.js';
import Title from './components/Title.js';
import { getEntries } from './services/entries.service.js';
import { getFamilyByUid, addFamily, getChildrenByFamilyId } from './services/families.service.js';
import { Typography, Grid, Divider, Stack } from '@mui/material';
// import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from './firebase';
import CssBaseline from '@mui/material/CssBaseline';
import { ArrowBackIos } from '@mui/icons-material';

export const ThemeContext = createContext(null);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
// const lightTheme = createTheme({
//   palette: {
//     mode: 'light',
//   },
// });

const App = () => {
  const [entries, setEntries] = useState([]);
  const [family, setFamily] = useState({});
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState({});
  const [updateEntries, setUpdateEntries] = useState(false);
  const [reloadChildren, setReloadChildren] = useState(false);
  /*   const [updateFamilies, setUpdateFamilies] = useState(false); */
  const [currentPage, setCurrentPage] = useState('home');
  const [isLogedIn, setIsLogedIn] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    drawerOpen ? setDrawerOpen(!drawerOpen) : setDrawerOpen(!drawerOpen);
  };

  const compare = (a, b) => {
    if (a.datetime < b.datetime) { return 1 };
    if (a.datetime > b.datetime) { return -1 };
    return 0;
  }

  const updateList = async () => {
    // setIsLoading(true);
    const response = await getEntries();
    setEntries(response.sort(compare));
    setUpdateEntries(false);
    // setIsLoading(false);
  }

  const showEntries = (pageName, child) => {
    setSelectedChild(child);
    setCurrentPage(pageName);
  }

  useEffect(() => {
    updateList();
  }, [updateEntries])

  useEffect(async () => {
    if (!!auth.currentUser && isLogedIn && !family.id) {
      try {
        setFamily(await getFamilyByUid(auth.currentUser.uid));
      } catch (error) {
        console.trace(error);
        addFamily("default", auth.currentUser.uid);
      }
    }
  }, [isLogedIn])

  useEffect(async () => {
    if (!!auth.currentUser && isLogedIn && !!family.id) {
      console.log("in if - loading children: ", children);
      try {
        setChildren(await getChildrenByFamilyId(family.id));
      } catch (error) {
        console.trace(error);
      }
    }
  }, [family])

  useEffect(() => {
    updateList();
    // get current User information
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        isLogedIn ? console.log("allready logged in") : setIsLogedIn(true);
        setCurrentPage("home");
        // ...
        // create family if needed

      } else {
        // User is signed out
        // ...
        console.log("from useEffect: user is logged out")
      }
    });
  }, []);

  let mainContent;
  if (currentPage === "home") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
      className="App"
    >
      <Grid item>
        <Title title="Home" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>
      <Grid item>
        {isLogedIn ? <Home family={family} children={children} setSelectedChild={setSelectedChild} setCurrentPage={setCurrentPage} /> : setCurrentPage("sign in")}
      </Grid>
    </Grid>;
  } else if (currentPage === "help") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      className="App"
    >
      <Grid item>
        <Title title="Hilfe" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>
      <Grid item>
        <Help />
      </Grid>
    </Grid>;
  } else if (currentPage === "history") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
      className="App"
    >
      <Grid item>
        <Title title="Verlauf" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>

      <Grid item>
        <InputBoard
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>

      <Grid item>
        <ListView
          entries={entries}
          updateEntries={updateEntries => setUpdateEntries(updateEntries)}
        />
      </Grid>
    </Grid>;
  } else if (currentPage === "stillen") {
    if (isLogedIn) {
      mainContent = <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh' }}
        className="App"
      >
        <Grid item height={'10vh'} width={'100%'}>
          <Title title={selectedChild.firstname} back={true} target="home" showEntries={showEntries} currentPage={currentPage} />
          <Divider />
        </Grid>
        <Grid item height="40vh">
          <DashboardStillen
            entries={entries}
            updateEntries={updateEntries => setUpdateEntries(updateEntries)}
            selectedChild={selectedChild}
          />
        </Grid>
        <Grid item
          height="auto"
          margin="auto">
          <InputBoard
            updateEntries={updateEntries => setUpdateEntries(updateEntries)}
            selectedChild={selectedChild}
          />
        </Grid>
      </Grid>;
    } else {
      setCurrentPage("sign in")
    }
  } else if (currentPage === "profile") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
      className="App"
    >
      <Grid item>
        <Title title="Profile" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>
      <Grid item>
        {isLogedIn ? <Profile family={family} children={children} userAuth={setIsLogedIn} setReloadChildren={setReloadChildren} /> : setCurrentPage("sign in")}
      </Grid>
    </Grid>;
  } else if (currentPage === "sign up") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      className="App"
    >
      <Grid item>
        <Title title="Account erstellen" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>
      <Grid item>
        <SignUp currentPageChanger={setCurrentPage} />
      </Grid>
    </Grid>;
  } else if (currentPage === "sign in") {
    mainContent = <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
      className="App"
    >
      <Grid item>
        <Title title="Log In" back={false} target="" showEntries={showEntries} currentPage={currentPage} />
        <Divider />
      </Grid>
      <Grid item>
        <SignIn
          currentPageChanger={setCurrentPage}
          userAuth={setIsLogedIn}
        />
      </Grid>
    </Grid>;
  }
  else {
    mainContent = <Typography>nothing to show for "{currentPage}"</Typography>
  }


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {mainContent}
      <BottomDrawer
        drawerOpen={drawerOpen}
        currentPageChanger={setCurrentPage}
        toggleDrawer={toggleDrawer}
        userAuth={setIsLogedIn}
      />
      <NavBarBottom
        zIndex={1901}
        currentPageChanger={setCurrentPage}
        toggleDrawer={toggleDrawer}
      />
    </ThemeProvider>
  )

}

export default App