import React from "react";
import { Drawer } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
//import BottomDrawer from "./BottomDrawer";

const BottomDrawer = ({ currentPageChanger, drawerOpen, toggleDrawer, userAuth, ...rest }) => {

    return (
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={toggleDrawer()}
            >
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => currentPageChanger('profile')}>
                            <ListItemText primary="Familie verwalten" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => currentPageChanger('help')}>
                            <ListItemText primary="Hilfe" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => userAuth(false)}>
                            <ListItemText primary="logout" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
    );
}

export default BottomDrawer;