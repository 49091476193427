import React, { useState } from 'react';
import { auth } from '../firebase';
import { Container, Paper, Stack, Typography } from '@mui/material';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogChildEdit from './DialogChildEdit.js';
import moment from 'moment';
import 'moment/locale/de-ch';


const Profile = (props) => {
    const user = auth.currentUser;
    const setReloadChildren = props.setReloadChildren;
    moment.locale('de-ch');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedChild, setSelectedChild] = useState({});

    const editChild = (child) => {
        setSelectedChild(child);
        //console.log("selectedChild: ", selectedChild);
        //console.log("editChild.child", child)
        handleDialogClick(child);
    }

    const handleDialogClick = (child) => {
        if (dialogOpen) {
            setDialogOpen(!dialogOpen);
            setSelectedChild({});
        }
        else {
            setDialogOpen(true);
        }
    }

    function Family(props) {
        const hasFamily = props.hasFamily;
        if (hasFamily) {
            return <Card>
                <CardContent>
                    <Typography>name: {props.family.name}</Typography>
                    <Typography>creator: {props.family.creator}</Typography>
                </CardContent>
            </Card>;
        }
        return <Typography>no family found!</Typography>;
    }

    function Children(props) {
        const hasChildren = props.hasChildren;
        const editChild = props.editChild;

        if (hasChildren) {
            return <List dense={true} sx={{ width: '100%', minWidth: '250px', maxWidth: 200, bgcolor: 'background.paper' }} >
                {props.children.map(child => (
                    <Button
                        key={child.id}
                        onClick={(e) => editChild(child)}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>C</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={child.firstname} secondary={moment(child.dob.seconds).format('Do MMMM YYYY')} />
                        </ListItem>
                    </Button>
                ))}
            </List >
        } else {
            return <Typography>no child found!</Typography>;
        }
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <>
            <Container component="main" maxWidth="m">
                <Stack spacing={4} sx={{ pb: '5rem'}}>
                    <Item>
                        <Typography variant='h3'>Meine Daten</Typography>
                        <Typography>displayName: {user.displayName}</Typography>
                        <Typography>email: {user.email}</Typography>
                        <Typography>UID: {user.uid}</Typography>
                    </Item>
                    <Item>
                        <Typography variant='h3'>Meine Familie</Typography>
                        <Family hasFamily={!!props.family} family={props.family} />
                    </Item>
                    <Item>
                        <Typography variant='h3'>Meine Kinder</Typography>
                        <Children hasChildren={props.children.length > 0} children={props.children} editChild={editChild} />
                        <DialogChildEdit
                            selectedChild={selectedChild}
                            setSelectedChild={setSelectedChild}
                            dialogOpen={dialogOpen}
                            setDialogOpen={setDialogOpen}
                            handleDialogClick={handleDialogClick}
                            setReloadChildren={setReloadChildren}
                        />
                    </Item>
                    <Item >
                        <Button
                            onClick={() => props.userAuth(false)}
                        >Log Out</Button>
                    </Item>
                </Stack>
            </Container>
        </>
    );
}

export default Profile;