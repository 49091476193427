import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import moment from 'moment';
import 'moment/locale/de-ch';

const DashboardStillen = (props) => {
  const [schoppenCount, setSchoppenCount] = useState(0);
  const [stillenCount, setStillenCount] = useState(0);
  const [stillenLastSide, setStillenLastSide] = useState("-");
  const [lastMealTime, setLastMealTime] = useState("-");
  const selectedChild = props.selectedChild;

  moment.locale('de-ch'); //regional settings for moment.js

  const updateStillenCount = () => {
    var todaysEntries = props.entries.filter(function (el) {
      return (
        (moment(el.datetime).isSame(moment(), 'day') &&
          el.side === 'L') ||
        (moment(el.datetime).isSame(moment(), 'day') &&
          el.side === 'R')
        ) && el.childId === props.selectedChild.id;
    });
    setStillenCount(todaysEntries.length);
  }

  const updateSchoppenCount = () => {
    var todaysEntries = props.entries.filter((el) => {
      return (
        moment(el.datetime).isSame(moment(), 'day') &&
        el.side !== 'L' &&
        el.side !== 'R'
      ) && el.childId === props.selectedChild.id;
    });
    setSchoppenCount(todaysEntries.length);
  }

  const getSortedEntriesList = async () => {
    let res = []
    try {
      res = props.entries.sort((a, b) => moment(a.datetime).isBefore(moment(b.datetime))).filter((entry) => { return entry.childId === selectedChild.id });
    } catch (error) {
      console.log(error);
      res = [];
    }
    return res;
  }

  const updateLastMealTime = async () => {
    let sortedList = await getSortedEntriesList();
    setLastMealTime(moment(sortedList[0].datetime).format('LT'));
  }

  const updateStillenLastSide = async () => {
    let sortedList = await getSortedEntriesList();
    var lastSide = sortedList.filter((el) => {
      return el.side !== 'S';
    });
    //console.log("lastSide", lastSide);
    setStillenLastSide(lastSide[0].side);
  }

  useEffect(() => {
    updateStillenCount();
    updateSchoppenCount();
    updateLastMealTime();
    updateStillenLastSide();
  }, [props.entries])

  return (
    <Grid
      container
      spacing={3}
      // direction="flow"
      // alignItems="center"
      style={{ width: '100vw' }}>
      <Grid item xs={6}>
        <Card>
          <CardContent align="center">
            <Typography variant="h3">{schoppenCount}</Typography>
            <Typography variant="h7">Schoppen</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent align="center">
            <Typography variant="h3">{stillenCount}</Typography>
            <Typography variant="h7">mal gestillt</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent align="center">
            <Typography variant="h3">{stillenLastSide}</Typography>
            <Typography variant="h7">letzte Seite</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent align="center">
            <Typography variant="h3">{lastMealTime}</Typography>
            <Typography variant="h7">letzte Mahlzeit</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DashboardStillen;
