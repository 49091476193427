import React from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

const Title = (props) => {

    function Element(props) {
        const title = props.title;
        const back = props.back;
        const target = props.target
        const showEntries = props.showEntries;
        const currentPage = props.currentPage;

        if (back && target == "home" && currentPage == "stillen") {
            return <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ArrowBackIos onClick={() => { showEntries(target, {}) }} sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} />
                <Typography variant="h4" sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} >{title}</Typography>
                <Typography variant="h4" sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} > </Typography>
            </Stack>
        } else if (back && target != "") {
            return <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ArrowBackIos onClick={() => { showEntries(target, {}) }} sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} />
                <Typography variant="h4" sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} >{title}</Typography>
                <Typography variant="h4" sx={{ sx: '2rem', mx: '2rem', lx: '2rem' }} > </Typography>
            </Stack>

        } else {
            return <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h4" sx={{ sx: 'auto', mx: 'auto', lx: 'auto' }} >{title}</Typography>
            </Stack>
        }
    }

    return (
        <Element title={props.title} back={props.back} target={props.target} showEntries={props.showEntries} currentPage={props.currentPage} />
    );
}

export default Title;