import React, { useState } from 'react';
import { List, Button, ListItem, ListItemText, ListItemAvatar, Avatar, DialogActions } from '@mui/material';
import { patchEntry, deleteEntry } from '../services/entries.service.js';
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { Stack, Select, MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import 'moment/locale/de-ch';

// Transition is needed for the slide Transition of the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ListView = ( props ) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [newSide, setNewSide] = useState("");
  const [newDatetime, setNewDatetime] = useState("");
  const handleDialogClick = (entry) => {
    if (dialogOpen)
      { 
        setDialogOpen(!dialogOpen); 
        setSelectedEntry({});
      } 
    else 
      { 
        if (typeof selectedEntry !== "undefined"){setNewDatetime("");setNewSide("");}else{
          setNewDatetime(selectedEntry.datetime);
          setNewSide(selectedEntry.side);
        }
        setDialogOpen(true);
      }
  }

  moment.locale('de-ch');

  const editEntry = (entry) => {
    setSelectedEntry(entry);
    //console.log("selectedEntry: ", selectedEntry);
    //console.log("editEntry.entry", entry)
    handleDialogClick(entry);
  }

  const updateSelectedEntry = (e) => {
    e.preventDefault();
    const entry = {
      id: selectedEntry.id,
      side: selectedEntry.side,
      datetime: selectedEntry.datetime
    }
    // console.log("old: ", entry);
    if (newSide) { 
      entry.side = newSide;
    }
    if (newDatetime) { 
      entry.datetime = newDatetime;
    }
    // console.log("new: ", entry);
    patchEntry(entry.id, entry.side, entry.datetime);
    props.updateEntries(true);
    handleDialogClick();
  }

  const deleteSelectedEntry = (e) => {
    e.preventDefault();
    deleteEntry(selectedEntry.id);
    props.updateEntries(true);
    handleDialogClick();
  }

  const getDate = (datetime) => {
    return moment(datetime).format('Do MMMM YYYY');
  }

  const getTime = (datetime) => {
    return moment(datetime).format('LT, dddd');
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClick}
        aria-describedby="alert-dialog-slide-description"
        className="dialog"
      >
        <DialogTitle
          // sx={{ width: "120px", m: 'auto'}}
        >Edit Entry</DialogTitle>
        <DialogContent
        dividers
        sx={{ maxWidth: "100%" }}
        >
          <Stack 
            component="form"
            noValidate
            spacing={2}
            alignItems="left"
          >
            <Select
              id="modify-side"
              value={newSide}
              label="new side"
              onChange={(e) => { setNewSide(e.target.value)}}
              sx={{ width: "60px"}}
            >
              <MenuItem value={"L"}>L</MenuItem>
              <MenuItem value={"R"}>R</MenuItem>
            </Select>
            <TextField
              id="modify-datetime"
              type="datetime-local"
              defaultValue={selectedEntry.datetime}
              onChange={(e) => {setNewDatetime(e.target.value)}}
              sx={{ width: 220}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions >
          <Button
            type="submit"
            variant="text"
            sx={{ mt: 1, mb: 1}}
            onClick={(e) => deleteSelectedEntry(e)}
            text="update"
            >delete</Button>
          <Button
            type="submit"
            variant="text"
            sx={{ mt: 1, mb: 1}}
            onClick={(e) => updateSelectedEntry(e)}
            text="update"
            >update</Button>
        </DialogActions>
      </Dialog>
      <List 
        dense={true}
        sx={{ width: '100%', minWidth: '250px', maxWidth: 200, bgcolor: 'background.paper' }}
        >
        { props.entries.map(entry =>(
          <Button 
            variant="text"
            key={entry.id} 
            onClick={(e) => editEntry(entry)}
            sx={{ maxWidth: "100%" }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>{entry.side}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={getTime(entry.datetime)} secondary={getDate(entry.datetime)}/>
            </ListItem>
          </Button>
        ))}
      </List>
    </>
  )
}

export default ListView;
