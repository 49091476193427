import { collection, addDoc, doc, getDoc, getDocs, query, where, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '../firebase';

export async function getFamilyByUid(userId) {
    /* console.log("families.service: getFamilies was called with userId: ", userId); */
    const q = query(collection(db, "families"), where("creator", "==", userId));
    const families = await getDocs(q);
    const result = []
    families.forEach((doc) => {
        /* console.trace(doc.id, " => ", doc.data()); */
        const d = doc.data()
        const r = {
            "id": doc.id,
            "name": d.name,
            "creator": d.creator,
            "members": d.members
        };
        result.push(r);
    });

    return result[0]
}

export async function getChildrenByFamilyId(familyId) {
    /* console.log("families.service: getFamilies was called with userId: ", userId); */
    const q = query(collection(db, "children"), where("familyId", "==", familyId));
    const children = await getDocs(q);
    const result = []
    children.forEach((doc) => {
        /* console.trace(doc.id, " => ", doc.data()); */
        const d = doc.data()
        const r = {
            "id": doc.id,
            "familyId": d.familyId,
            "firstname": d.firstname,
            "lastname": d.lastname,
            "dob": d.dob.toDate()
        };
        result.push(r);
    });

    return result
}

export async function addFamily(familyName, userId) {
    try {
        const docRef = await addDoc(collection(db, "families"), {
            name: familyName,
            creator: userId
        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}
export async function addChild(firstname, lastname, dob, familyId) {
    try {
        const docRef = await addDoc(collection(db, "children"), {
            firstname: firstname,
            lastname: lastname,
            dob: dob,
            familyId: familyId
        });
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function updateChild(child) {
    try {
        const docRef = collection('children').doc(child.id).get();
        await updateDoc(docRef, {
            firstname: child.firstname,
            lastname: child.lastname,
            dob: child.dob
        });
    } catch (e) {
        console.error("Error updating document: ", e);
    }
}

export async function deleteChild(child) {
    try {
        const docRef = collection('children').doc(child.id).get();
        await deleteDoc(docRef);
    } catch (e) {
        console.error("Error deleting document: ", e);
    }
}