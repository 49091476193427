import React from 'react';
import { Dialog, DialogTitle, DialogContent, MenuItem, TextField, DialogActions, Stack, Button, Slide } from '@mui/material';
import { addChild, updateChild, deleteChild } from '../services/families.service';
import moment from 'moment';
import 'moment/locale/de-ch';

// Transition is needed for the slide Transition of the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogChildEdit = (props) => {
    const selectedChild = props.selectedChild;
    const setSelectedChild = props.setSelectedChild
    const dialogOpen = props.dialogOpen;
    const setDialogOpen = props.setDialogOpen;
    const handleDialogClick = props.handleDialogClick;
    const setReloadChildren = props.setReloadChildren;
    moment.locale('de-ch');

    const updateSelectedChild = (e) => {
        e.preventDefault();
        const child = {
            id: selectedChild.id,
            firstname: selectedChild.firstname,
            lastname: selectedChild.lastname,
            dob: selectedChild.dob
        }

        updateChild(child);
        setReloadChildren(true);
        handleDialogClick();
    }

    const deleteSelectedChild = (e) => {
        e.preventDefault();
        deleteChild(selectedChild);
        props.setReloadChildren(true);
        handleDialogClick();
    }

    return (
        <Dialog
            open={dialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDialogClick}
            aria-describedby="alert-dialog-slide-description"
            className="dialog"
        >
            <DialogTitle
            // sx={{ width: "120px", m: 'auto'}}
            >Edit Child</DialogTitle>
            <DialogContent
                dividers
                sx={{ maxWidth: "100%" }}
            >
                <Stack
                    component="form"
                    noValidate
                    spacing={2}
                    alignItems="left"
                >
                    <TextField
                        id="modify-firstname"
                        defaultValue={!!selectedChild && !!selectedChild.firstname ? selectedChild.firstname : ""}
                        onChange={(e) => { selectedChild.firstname = e.target.value }}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="modify-lastname"
                        defaultValue={!!selectedChild && !!selectedChild.lastname ? selectedChild.lastname : ""}
                        onChange={(e) => { selectedChild.lastname = e.target.value }}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="modify-dob"
                        type="datetime-local"
                        defaultValue={new Date(moment(selectedChild.dob).toISOString())}
                        onChange={(e) => { selectedChild.dob = e.target.value }}
                        sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions >
                <Button
                    type="submit"
                    variant="text"
                    sx={{ my: 1 }}
                    onClick={(e) => deleteSelectedChild(e)}
                    text="delete"
                >delete</Button>
                <Button
                    type="submit"
                    variant="text"
                    sx={{ my: 1 }}
                    onClick={(e) => updateSelectedChild(e)}
                    text="update"
                >update</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogChildEdit;