import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, Grid } from '@mui/material'
import { addEntry } from '../services/entries.service.js'
import { updateOldEntries, addEntryNew } from '../services/entries.service.js'

const InputBoard = (props) => {

    let handleClick = (side) => {
        let id = uuidv4().toString();
        let d = Date.now();
        const date = new Date(d);
        let dateTime = date.toISOString();
        
        if (side === 'U') {

        } else if (side === 'U') {
            addEntryNew(side, id, dateTime, props.selectedChild.id);
        } else {
            addEntry(side, id, dateTime, props.selectedChild.id);
            props.updateEntries(true)
        }
    }

    return (
        <Grid
            container
            spacing={2}
            style={{ minWidth: '250px', maxWidth: '500px' }}
        >
            <Grid item xs={6}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0 }}
                    onClick={() => handleClick('L')}
                >Links</Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0 }}
                    onClick={() => handleClick('R')}
                >Rechts</Button>
            </Grid>
            {/* SCHOPPEN Button ready for implementation */}
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0 }}
                    onClick={() => handleClick('S')}
                >Schoppen</Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 0 }}
                    onClick={() => handleClick('N')}
                >Schoppen Neu</Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 0, mb: 1 }}
                    onClick={() => handleClick('U')}
                >Update old Entries</Button>
            </Grid>
        </Grid>
    )
}

export default InputBoard