import { auth } from '../firebase';
import { Container, Paper, Stack, Divider, Typography } from '@mui/material';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/de-ch';

const Home = (props) => {

    const showEntries = (pageName, child) => {
        props.setSelectedChild(child);
        props.setCurrentPage(pageName);
    }

    function Children(props) {
        const hasChildren = props.hasChildren;

        if (hasChildren) {
            //console.trace(props.children);
            return <List dense={true} sx={{ width: '100%', minWidth: '250px', maxWidth: 200, bgcolor: 'background.paper' }}>
                {props.children.map(child => (
                    <Button
                        key={child.id}
                        onClick={() => showEntries('stillen', child)}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>C</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={child.firstname} secondary={moment(child.dob.seconds).format('Do MMMM YYYY')} />
                        </ListItem>
                    </Button>
                ))}
            </List >
        } else {
            return <Typography>no child found!</Typography>;
        }
    }

    return (
        <Children hasChildren={props.children.length > 0} children={props.children} />
    );
}

export default Home;