import { auth } from '../firebase';
import { Container, Paper, Stack, Divider, Typography } from '@mui/material';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/de-ch';

const Help = (props) => {
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <>
            <Typography>Help</Typography>
        </>
    );
}

export default Help;