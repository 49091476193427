import React, { useState, useEffect } from 'react';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ChildCareIcon from '@mui/icons-material/ChildCare';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import InsightsIcon from '@mui/icons-material/Insights';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MenuIcon from '@mui/icons-material/Menu';
import { Home, Person, ImportContacts, WatchLater } from '@mui/icons-material';

const NavBarBottom = ({currentPageChanger, toggleDrawer, ...rest}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        
    },[]);

    return (
        <Paper 
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} 
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
{/*                 <BottomNavigationAction 
                    label="Menu" 
                    icon={<MenuIcon />} 
                    onClick={toggleDrawer(true)}
                /> */}
                <BottomNavigationAction 
                    label="Home" 
                    icon={<Home />} 
                    onClick={() => currentPageChanger('home')}
                />
{/*                 <BottomNavigationAction 
                    label="Stillen" 
                    icon={<ChildCareIcon />} 
                    onClick={() => currentPageChanger('stillen')}
                /> */}
                <BottomNavigationAction 
                    label="Verlauf" 
                    icon={<WatchLater />} 
                    onClick={() => currentPageChanger('history')}
                />
                <BottomNavigationAction 
                    label="Anleitung" 
                    icon={<ImportContacts />} 
                    onClick={() => currentPageChanger('help')}
                />
                <BottomNavigationAction 
                    label="Konto" 
                    icon={<Person />} 
                    onClick={() => currentPageChanger('profile')}
                />
            </BottomNavigation>
        </Paper>
    );
}

export default NavBarBottom;